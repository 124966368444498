import React from "react"

import Layout from "../components/layout"
import RecentPosts from '../components/recent-post-list'

import {graphql} from "gatsby"
import get from 'lodash/get'


// import * as styles from "../components/index.module.css"

export default class IndexPage extends React.Component {
  render() {
  const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout pageTitle="Home" pageWidth="65%">
        <div>
          <h1>Sup</h1>
          <p>This website is still a work in progress. I was going to get lazy and just make a site with Squarespace, but I hated how little I could customize and didn't want to pay $26/month, so here we are.</p>
          <p>At some point, I will hopefully make this site much prettier and flashier. Also hopefully once I clean up and make sure there is nothing super duper secret in here, I will make the repo public. In the meantime, feel free to check out the blog at <a href="/blog/">/blog</a>.</p>
          <p>I mainly made this website to see if I could still make one, but also some day, I will hopefully use this to blog and share the videos I want to make about the things I am interested in</p>
          <RecentPosts blogData={posts}/>
          {/* <p>Some details about the <a href="/projectblog/website-setup" title="Website setup article">website setup.</a></p> */}
        </div>
        <hr/>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
{
allContentfulBlogPost (sort: { fields: [publishDate], order: DESC }, limit: 10) {
    edges {
      node {
        title
        slug
        publishDate(formatString: "MMM D, YYYY")
      }
    }
  }
}
`