import React from 'react'
import { Link } from 'gatsby'

import * as styles from "./recent-post-list.module.css"

const RecentPosts = ({blogData}) => {

    const blogPostPrefix = "/blog/"

    return (
        <>
        <div>
            <h4>Recent Posts</h4>
            <ul className={styles.postList}>
            {blogData.map(({ node }) => {
                return (
                    <li key={node.slug} className={styles.postListItem}>
                        <span><Link to={blogPostPrefix + node.slug}>{node.title}</Link> ({node.publishDate})</span>
                    </li>
                )
            })}
            </ul>
        </div>         
        </>
    )
};

export default RecentPosts